import React from "react";
import "./style/Button.scss";

const Button = ({children, link}) => {

    function mouseHover(e) {

    }
    
    return (
    <div className="bttn" onMouseEnter={mouseHover}>
        <a href={link}>
        {children}
        </a>
    </div>
    )
}

export default Button;
