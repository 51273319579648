import React from 'react';
import logo from '../assets/images/logo.svg';
import Button from './Button';
import "./style/Header.scss";
import vk from '../assets/icons/vk.svg'
import tg from '../assets/icons/tg.svg'

function Header() {

    return (
        <div className="Header">

            <div>
                <img
                    src={logo}
                    className="Logo"
                    alt="logo"/>
                <div className='vert-line'></div>
                <Button link={'https://dfmarket.ru/index.php?route=common/home'}>Магазин символики</Button>
            </div>

            <div>
                <Button link={'mailto:okdf@physics.msu.ru'}>
                    <span>okdf@physics.msu.ru</span>
                </Button>
                <Button link={'https://vk.com/df_msu'}><img src={vk}
                        alt="vk"/></Button>
                <Button link={'https://t.me/df_msu'}><img src={tg}
                        alt="tg"/></Button>
            </div>

        </div>
    )
}

export default Header
