import React from 'react';
import ItemList from './ItemList';
import "./style/List.scss";

function List({items}) {
    
    const listItems = items.map((item) => 
        <ItemList time={item.time} events={item.events}>
        </ItemList>
    );

    return (
        <div className="List">
            {listItems}
        </div>
    )
};

export default List