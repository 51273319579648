import React from "react";
import "./style/ItemList.scss";

function ItemList({ time, events }) {
   
    const listEvents = events.map((event) => 
        <div className="div_event">
            <div className="div_title">
                {event.title}
            </div>
            <div className="badge_place">
                {event.place}
            </div>
        </div>
    );

    return (
        <div className="ItemList">
            <div className="time">
                {time}
            </div>
            <div className="list_event">
                {listEvents}
            </div>
        </div>
    )
};

export default ItemList
