import React from 'react';
import ReactDOM from 'react-dom';
import Header from './Header';
import Content from './Content';

function App() { 
  return (
    <div className="App">
      <Header />
      <Content />
    </div>
  );
}

export default App;