import React from 'react';
import List from './List';
import "./style/Content.scss";

function Content() {

    const items = [
        {
            time: '08:30',
            events: [
                {
                    title: 'Побудка',
                    place: 'ДСЛ'
                }
            ]
        },
        {
            time: '10:00',
            events: [
                {
                    title: 'Утренняя встреча',
                    place: 'Холл 1 и 2 этаж'
                }
            ]
        },
        {
            time: '10:30',
            events: [
                {
                    title: 'Фотовыставка',
                    place: 'Холл ЦФА'
                },
                {
                    title: 'Конкурсы',
                    place: '5 этаж Юг'
                },
                {
                    title: ['Арт-физика | ','Мастер классы | ','Шахматы'],
                    place: 'Площадь перед ФФ'
                },
            ]
        },
        {
            time: '11:00',
            events: [
                {
                    title: 'Игра на отчисление',
                    place: 'ЦФА'
                }
            ]
        },
        {
            time: '12:45',
            events: [
                {
                    title: 'Шествие',
                    place: 'Площадь перед ФФ'
                }
            ]
        },
        {
            time: '13:30',
            events: [
                {
                    title: 'Открытие',
                    place: 'Главная сцена'
                }
            ]
        },
        {
            time: '14:00',
            events: [
                {
                    title: 'Ступеньки',
                    place: 'Главная сцена'
                }
            ]
        },
        {
            time: '15:00',
            events: [
                {
                    title: 'Гостевой концерт',
                    place: 'ЦФА'
                }
            ]
        },
        {
            time: '17:30',
            events: [
                {
                    title: 'Вечерний концерт',
                    place: 'Главная сцена'
                }
            ]
        }
    ];

    return (
        <div className="Content">
            <div className="Banner">
                <div className="Logo"></div>
            </div>
            <div className='Data'>
                <div className='date'>18 МАЯ</div>
                <List items={items} />
            </div>

        </div>
    )
}

export default Content
